@font-face {
    font-family: BPGBannerExtraSquareCaps;
    src: url(/src/fonts/BPGBannerExtraSquareCaps.woff) format("woff2"), url(/src/fonts/BPGBannerExtraSquareCaps.woff2) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
body{
    height: 100%; 
    overflow: hidden;
}
.main{
    font-family: BPGBannerExtraSquareCaps;
    display: flex;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

}
.left{
    width: 50%;
    height: 100vh;
    background-image: linear-gradient( -153deg, rgb(12,12,12) 0%, rgb(35,44,51) 100%);
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    transition: 0.5s;
    justify-content: center;
}
.left_none{
    transform: rotate(180deg);
    background-image: linear-gradient( -153deg, rgb(12,12,12) 0%, rgb(35,44,51) 100%);
    width: 0%;
    display: flex;
    align-items: center;
    height: 100vh;
    transition: 0.5s;
    z-index: 0;
}
.right{
    width: 50%;
    height: 100vh;
    background-image: linear-gradient( -123deg, #0b4a57, #969f02);
    display: flex;
    align-items: center;
    transition: 0.5s;
    justify-content: center;
    overflow: hidden !important;
}
.right_none{
    background-image: linear-gradient( -123deg, #0b4a57, #969f02);
    width: 0%;
    display: flex;
    align-items: center;
    height: 100vh;
    transition: 0.5s;
    z-index: 0;
}
.left_animation{
    z-index: 1;
    transform: rotate(180deg);
    width: 100%;
    height: 100vh;
    transition: 0.5s;  
    background-image: linear-gradient( -153deg, rgb(12,12,12) 0%, rgb(35,44,51) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.right_animation{
    width: 100%;
    height: 100vh;
    transition: 0.5s;
    background-image: linear-gradient( -123deg, #0b4a57, #969f02);
    display: flex;
    align-items: center;
    justify-content: center;
}
.rich_container{
    display: flex;
    justify-content: center;
    align-items: center;
    zoom: 80%;
    transition: 0.5s;
    margin-left: 1.3rem;
}
.cheap_container{
    display: flex;
    justify-content: center;
    align-items: center;
    zoom: 80%;
    transition: 0.5s;
    transform: rotate(180deg) !important;
}
.rich_box{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: 0.5s;
}
.rich_box_animated{
    display: flex;
    align-items: center;
    z-index: 1;
    transition: 0.5s;
}
.cheap_box{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: 0.5s;
}
.cheap_box_animated{
    display: flex;
    align-items: center;
    z-index: 1;
    transition: 0.5s;
}
.rich_shadow{
    margin-left: 4.375rem;
    border-radius: 14px;
    background-color: rgba(32, 37, 38, 0.55);
    width: 26.938rem;
    height: 36.375rem;
    z-index: 1;
    transition: 0.5s;
}
@keyframes in {
    from {
       width: 26.938rem;
       background-color: rgba(32, 37, 38, 0.55);
   }
    to {
       width: 80rem;
       background-color:#42855e79;
        box-shadow: inset 0 0 80px #d0ff007e;
   }
}
@keyframes out {
    from {
       width: 80rem;
       background-color:#42855e79;
        box-shadow: inset 0 0 80px #d0ff007e;
   }
    to {
       width: 26.938rem;
       background-color: rgba(32, 37, 38, 0.55);
   }
}
.rich_shadow_animation_in{
    margin-left: 4.375rem;
    border-radius: 14px;
    background-color: rgb(32, 37, 38);
    width: 26.938rem;
    height: 36.375rem;
    z-index: 1;
    transition: 0.5s;
    animation: in 0.5s;
    -webkit-animation-fill-mode: forwards;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}
.rich_shadow_animation_out{
    margin-left: 4.375rem;
    border-radius: 14px;
    background-color: rgb(32, 37, 38);
    width: 26.938rem;
    height: 36.375rem;
    z-index: 1;
    transition: 0.5s;
    animation: out 0.5s;
    -webkit-animation-fill-mode: forwards;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}
@keyframes c_in {
    from {
        width: 26.938rem;
        background-color: rgb(32, 37, 38);
   }
    to {
        width: 80rem;
        background-color: #202526;
        box-shadow: inset 0 0 50px #00587d;
   }
}
@keyframes c_out {
    from {
        width: 80rem;
        background-color: #202526;
        box-shadow: inset 0 0 50px #00587d;
   }
    to {
        width: 26.938rem;
        background-color: rgb(32, 37, 38);
   }
}
.cheap_shadow{
    margin-right: 4.375rem;
    border-radius: 14px;
    background-color: rgb(32, 37, 38);
    opacity: 0.549;
    width: 26.938rem;
    height: 36.375rem;
    z-index: 1;
    transition: 0.5s;
    margin-left: 4.375rem;
}
.cheap_shadow_animation_in{
    margin-left: 4.375rem;
    border-radius: 14px;
    background-color: rgb(32, 37, 38);
    width: 26.938rem;
    height: 36.375rem;
    z-index: 1;
    transition: 0.5s;
    animation: c_in 0.5s;
    -webkit-animation-fill-mode: forwards;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}
.cheap_shadow_animation_out{
    margin-right: 4.375rem;
    border-radius: 14px;
    background-color: rgb(32, 37, 38);
    opacity: 0.549;
    width: 26.938rem;
    height: 36.375rem;
    z-index: 1;
    transition: 0.5s;
    margin-left: 4.375rem;
    animation: c_out 0.5s;
    -webkit-animation-fill-mode: forwards;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}
.cheap_left{
    transition: 0.5s;
}
.rich_left{
    transition: 0.5s;
    
}
.cheap_right{
    margin-bottom: 7rem;
    margin-right: 2.625rem;
    
}
.cheap_right_none{
    display: none;
}
.rich_icon{
    width: 37.5rem;
    height: 50rem;
    z-index: 3;
    background-image: url(./images/richbox.png);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin-left: 15px;
    transition: 0.5s;
}
.cheap_icon{
    width: 37.5rem;
    height: 50rem;
    z-index: 3;
    background-image: url(./images/cheapbox.png);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin-right: 3.25rem;
    transition: 0.5s;
}
.rich_icon_animated{
    width: 37.5rem;
    height: 50rem;
    z-index: 3;
    background-image: url(./images/richbox.png);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin-left: -7rem;
    transition: 0.5s;
}
.cheap_icon:hover{
    background-size: 110%;
    transition: 0.5s;
}
.rich_icon:hover{
    background-size: 108%;
    transition: 0.5s;
}
.cheap_icon_animated{
    width: 37.5rem;
    height: 50rem;
    z-index: 3;
    background-image: url(./images/cheapbox.png);
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin-left: -7rem;
    transition: 0.5s;
}
.buy_button2{
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    width: 20.75rem;
    height: 5.25rem;
    margin-left: 7.5rem;
    margin-top: -2.5rem;
    z-index: 4;
    position: relative;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    background-color: rgb(136, 205, 37);
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transform-style: preserve-3d;
    transition: transform 0.3s cubic-bezier(0,0,0.58,1), background 0.3s cubic-bezier(0,0,0.58,1);
    cursor: pointer;
    transition: 0.5s;
}
.fx2{
    margin-left: 3rem !important;
}
.buy_button2:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #118b01;
    border-radius: inherit;
    transform: translate3d(0, 0.3em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.buy_button2:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    filter: blur(4px);
    border-radius: inherit;
    top: 0;
    left: 0;
    transform: translate3d(0, .5em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.buy_button2:hover {
    transform: translateY(-0.15em);
}
.buy_button2:hover:before {
    transform: translate3d(0, 0.5em, -1em);
}
.buy_button2:hover:after {
    transform: translate3d(0, 0.5em, -1em);
}
.buy_button2:active {
    transform: translate(0, 0.3em);
}
.buy_button2:active:before, .buy_button2:active:after {
    transform: translate3d(0,0,-1em);
}
.rich_price_cont , .cheap_price_cont{
    width: 16.125rem;
    height: 7.25rem;
    margin-left: 10rem;
    margin-bottom: 0rem;
    z-index: 4;
    transition: 0.5s;
}
.del{
    display: none;
}
.rich_left_animated{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cheap_left_animated{
    display: flex;
    justify-content: center;
    align-items: center;
}
.rich_price{
    border-radius: 31px;
    background-color: rgb(255, 156, 0);
    width: 100%;
    height: 100%;
    z-index: 4;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 7rem;
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    transition: 0.5s;
}
.cheap_price{
    border-radius: 31px;
    background-color: #0b4a57;
    width: 100%;
    height: 100%;
    z-index: 4;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 7rem;
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    text-shadow:1px 1px 5px #00eaff, 1px 1px 5px #00eaff;
}
.rich_price_icon{
    background-image: url(./images/crown.png);
    width: 70px;
    height: 50px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    transition: 0.5s;
}
.cheap_price_icon{
    width: 70px;
    height: 50px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}
.rich_right{
    margin-left: 7rem;
    margin-bottom: 7rem;
    transition: 0.5s;
}
.rich_right_header{
    color: #88cd25;
    font-size: 50px;
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    width: 30rem;
}
.cheap_right_header{
    color: #88cd25;
    font-size: 50px;
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    text-align: right;
}
.rich_right_desc{
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    color: white;
    font-size: 20px;
    margin-top: 8rem;
    width: 27.5rem;
}
.cheap_right_desc{
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    color: white;
    font-size: 20px;
    margin-top: 8rem;
    width: 27.5rem;
    text-align: right;
}
.fix{
    display: flex;
    justify-content: center;
    align-items: center;
}
.animate__animated.animate__fadeOutRight {
    --animate-duration: 6s;
}
.animate__animated.animate__fadeOutLeft {
    --animate-duration: 6s;
}
.animate__animated.animate__fadeOut {
    --animate-duration: 0s;
}
::-webkit-scrollbar {
    background: #161a1e;
    width: 6px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #969f02), color-stop(0.72, #0b4a57), color-stop(0.86, #0b4a57));
}
.rich_order_container{
    transition: 0.5s;
}
.cheap_order_container{
     transition: 0.5s;
}
.rich_order_header{
    color: #88cd25;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    transition: 0.5s;
}
.cheap_order_header{
    color: #88cd25;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    transition: 0.5s;
}
.order_list{
    margin: auto;
    zoom: 90%;
    transition: 0.5s;
}
.mrg{
    margin-top: 4rem !important;
}
.rich_order_line_title{
    color: white;
    font-size: 22px;
    text-align: right;
    width: 400px;
}
.cheap_order_line_title{
    color: white;
    font-size: 22px;
    text-align: right;
    width: 400px;
}
.cheap_order_info{
    margin-right: 10rem;
    margin-top: -8rem;
}
.rich_order_info{
    margin-right: 10rem;
    margin-top: -8rem;
}
.rich_back{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 2.8rem;
    top: 2.8rem;
    background-image: url(./images/arrow.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    fill: white;
    transition: 0.3s;
}
.rich_back:hover{
    background-image: url(./images/arrow2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
}
.cheap_back{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 2.8rem;
    top: 2.8rem;
    background-image: url(./images/arrow3.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    fill: white;
    transition: 0.3s;
    transform: rotate(180deg);
}
.cheap_back:hover{
    background-image: url(./images/arrow4.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
}
.rich_order_input{
    height: 34px;
    width: 280px;
    margin-left: 3rem;
    outline: none;
    background-color: #639e0f;
    border: none;
    color: white;
    opacity: 1;
    padding-left: 10px;
    border-radius: 4px;
    font-family: BPGBannerExtraSquareCaps;
    font-size: 18px;
}
.rich_order_input::placeholder {
    color: rgba(255, 255, 255, 0.296);
}
.cheap_order_input{
    height: 34px;
    width: 280px;
    margin-left: 3rem;
    outline: none;
    background-color: #639e0f;
    border: none;
    color: white;
    opacity: 1;
    padding-left: 10px;
    border-radius: 4px;
    font-family: BPGBannerExtraSquareCaps;
    font-size: 18px;
}
.cheap_order_input::placeholder {
    color: rgba(255, 255, 255, 0.296);
}
.rich_order_line{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.cheap_order_line{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.rad{
    display: flex;
    align-items: center;
    height: 34px;
    width: 280px;
    margin-left: 3.8rem;
}
.rem{
    margin-left: -12px !important;
}
.control {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 21px;
    color: white;
    font-weight: 800;
    letter-spacing: 1px;
    margin-left: 20px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #6da220;
}
.control--radio .control__indicator {
    border-radius: 50%;
}
.control:hover input ~ .control__indicator, .control input:focus ~ .control__indicator {
    background: #74a04d98;
}
.control input:checked ~ .control__indicator {
}
.control:hover input:not([disabled]):checked ~ .control__indicator, .control input:checked:focus ~ .control__indicator {
}
.control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control__indicator:after {
    display: block;
}
.control__indicator:after {
    border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
    left: 4px;
    top: 4px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
}
.but_price{
    color: #88cd25;
    font-size: 30px;
    font-weight: 800;
}
.rich_order_purchase_cont{
    display: flex;
    align-items: center;
    position: relative;
}
.cheap_order_purchase_cont{
    display: flex;
    align-items: center;
    position: relative;
}
.ord_cont{
    display: flex;
    align-items: center;
    position: relative;
    position: absolute;
    right: 3rem;
    margin-bottom: -12rem;
}
.buy_button3{
    margin-left: 4rem;
    zoom: 80%;
    font-family: BPGBannerExtraSquareCaps;
    font-weight: 800;
    width: 15.75rem;
    height: 4.25rem;
    z-index: 4;
    position: relative;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    background-color: rgb(136, 205, 37);
    font-size: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transform-style: preserve-3d;
    transition: transform 0.3s cubic-bezier(0,0,0.58,1), background 0.3s cubic-bezier(0,0,0.58,1);
    cursor: pointer;
    transition: 0.5s;
    right: 0px;
}
.buy_button3:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #118b01;
    border-radius: inherit;
    transform: translate3d(0, 0.3em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.buy_button3:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    filter: blur(4px);
    border-radius: inherit;
    top: 0;
    left: 0;
    transform: translate3d(0, .5em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.buy_button3:hover {
    transform: translateY(-0.15em);
}
.buy_button3:hover:before {
    transform: translate3d(0, 0.5em, -1em);
}
.buy_button3:hover:after {
    transform: translate3d(0, 0.5em, -1em);
}
.buy_button3:active {
    transform: translate(0, 0.3em);
}
.buy_button3:active:before, .buy_button3:active:after {
    transform: translate3d(0,0,-1em);
}
@media only screen and (max-width: 3840px) {
    .rich_container {
        zoom: 160%;
   }
    .cheap_container {
        zoom: 160%;
   }
}
@media only screen and (max-width: 2560px) {
    .rich_container {
        zoom: 110%;
   }
    .cheap_container {
        zoom: 110%;
   }
}
@media only screen and (max-width: 1920px) {
    .rich_container {
        zoom: 80%;
   }
    .cheap_container {
        zoom: 80%;
   }
}
@media only screen and (max-width: 1536px) {
    .rich_container {
        zoom: 65%;
   }
    .cheap_container {
        zoom: 65%;
   }
}
@media only screen and (max-width: 1440px) {
    .rich_container {
        zoom: 55%;
   }
    .cheap_container {
        zoom: 55%;
   }
}
@media only screen and (max-width: 1024px) {
    .rich_container {
        zoom: 70%;
   }
    .cheap_container {
        zoom: 70%;
   }
    .main {
        display: block;
   }
    .left {
        width: 100%;
        justify-content: center;
   }
    .right{
        width: 100%;
        justify-content: center;
        border-left: none;
   }
}
@media only screen and (max-width: 540px) {
    .rich_container {
        zoom: 40%;
   }
    .cheap_container {
        zoom: 40%;
   }
    .main {
        display: block;
   }
    .left {
        width: 100%;
        justify-content: center;
   }
    .right{
        width: 100%;
        justify-content: center;
        border-left: none;
   }
}
@media only screen and (max-width: 530px) {
    .rich_left{
        margin-left: -3.4rem;
   }
    .left{
        transform: none;
   }
    .cheap_left{
        transform: none;
   }
    .cheap_icon{
        margin-right: 2.25rem;
   }
    .fx2{
        margin: auto !important;
        margin-top: -2.5rem !important;
   }
    .cheap_price_cont{
        margin: auto;
   }
    .cheap_shadow{
        margin-right: 0px;
   }
    .cheap_right_desc{
        max-width: 35rem;
   }
    .rich_right{
        margin: 0px !important;
        margin: 0px !important;
        margin-top: 6rem !important;
   }
    .cheap_right{
        transform: none !important;
        margin: 0px !important;
        margin-top: 6rem !important;
   }
    .cheap_right_desc{
        text-align: center;
   }
    .cheap_right_header{
        text-align: center;
   }
    .rich_right_desc{
        text-align: center;
   }
    .cheap_right_header{
        text-align: center;
   }
    .rich_container {
        zoom: 50%;
        display: block;
   }
    .cheap_container {
        zoom: 50%;
        display: block;
   }
    .main {
        display: block;
   }
    .left {
        width: 100%;
        justify-content: center;
   }
    .right{
        width: 100%;
        justify-content: center;
        border-left: none;
   }
}
